'use client';

import Image from 'next/image';
import styles from '../ErrorCommon.module.scss';
import classNames from 'classnames/bind';
import Button from 'ui/common/button/Button';
import { useRouter } from 'next/navigation';
import { URL_PATH } from 'ui/hook/useUrlRouter';
import { SOLUTION_TYPE } from 'ui/util/solution';
import { getDesignV2 } from '@api/design';
import { getPrefix } from '@utils/headerInstance';

const cx = classNames.bind(styles);

const Error404 = () => {
  const router = useRouter();

  return (
    <div className={cx('error-screen')}>
      <Image src={'/img/icon/icon_layer_blue.svg'} alt={'404Error'} width={104} height={104} />
      <h1>
        <span>요청하신 페이지를</span> <span>찾을 수 없어요!</span>
      </h1>
      <p>
        페이지가 존재하지 않거나 사용할 수 없는 페이지입니다.
        <br />
        입력하신 주소가 정확한지 다시 한번 확인해주세요.
      </p>

      <Button
        className={cx('button')}
        onClick={async () => {
          const { pageList } = await getDesignV2({});
          const main = pageList.find((page) => page.mainPageYn)!;

          router.push(`${URL_PATH[SOLUTION_TYPE](`${main.urlPath}`)}`);
        }}
      >
        홈으로 돌아가기
      </Button>
    </div>
  );
};

export default Error404;
