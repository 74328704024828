import { ReadonlyURLSearchParams, useRouter, useSearchParams } from 'next/navigation';
import { isHttpReg } from '../util/reg';
import { SOLUTION_TYPE } from '../util/solution';
interface IHandleUrlClickProps {
  url?: string;
  isExternal: boolean;
  isPreview?: boolean;
}

export const URL_PATH = {
  JOBFLEX: (url: string) => `/career/${url}`,
  JOBDA: (url: string) => `/${url}`,
};

const useUrlRouter = () => {
  const router = useRouter();

  const handleUrlClick = ({ url, isExternal, isPreview = false }: IHandleUrlClickProps) => {
    if (!url) return;

    if (isExternal) {
      return window.open(isHttpReg(url) ? url : `https://${url}`, '_blank');
    }

    router.push(isPreview ? url : URL_PATH[SOLUTION_TYPE](url));
  };

  return { handleUrlClick, router };
};

export default useUrlRouter;
