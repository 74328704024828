/* hex validation */
export const hexRegex = (value: string) => /^#?([a-fA-F0-9]{6})$/.test(value);

/* 숫자검출 정규식 */
export const isNumberReg = (value: string) => {
  return /^[0-9]+$/.test(value);
};

/* http, https ~ 정규식 */
export const isHttpReg = (value: string) => /(http(s)?:\/\/)/gi.test(value);

export const isIncludeProtocolReg = (value: string) =>
  /^(https?:\/\/)?(www\.)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(\/\S*)?$/.test(value);

/* 한글 정규식 */
export const isKorReg = (value: string) => {
  return /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/.test(value);
};

/* 이메일 정규식 */
export const isEmailReg = (value: string) => {
  return /^[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/.test(value);
};

/* 이모지 여부 확인 */
export const EMOJI =
  /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;

export const isContainEmojiReg = (input: string): boolean => {
  return EMOJI.test(input);
};
